import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import apiRequestGet from "../Api";

function Profile() {
  const props = useParams()
  const [fullName, setFullName] = useState("")
  const [formUrl, setFormUrl] = useState("")

  const fetchData = useCallback(async () => {
    const params = { contact_id: props.contactId };
    const contactData = await apiRequestGet("profile", params);
    setFullName(contactData.full_name);
    setFormUrl(contactData.form_url)
  }, [props.contactId]);

  useEffect(() => {fetchData()}, [fetchData]);
    useEffect(() => {
    document.title = `Вас пригласил(а) ${fullName}`;
  }, [fullName]);

  return (
    <
      div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: "#2a3f35"
      }}
    >
    <Card style={{ width: '18rem', background: "#2a3f35" }}>
      <Card.Img variant="top" src={`https://sinkoveco.nov.ru/backend/qr?contact_id=` + props.contactId} />
      <Card.Body>
        <Card.Title>Вас пригласил(а) {fullName}</Card.Title>
      </Card.Body>
      <ListGroup className="list-group-flush" >
        <ListGroup.Item style={{ background: "#2a3f35" }}>
          Заполните форму и вам будут начислены баллы.
        </ListGroup.Item>
      </ListGroup>
      <Card.Body>
        <Card.Link href={ formUrl }>{ formUrl }</Card.Link>

      </Card.Body>
    </Card>
    </div>
)
}


export default Profile;