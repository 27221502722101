import axios from 'axios';

const apiRequestGet = async (router, data) => {
  const host = "https://sinkoveco.nov.ru/backend/"
  try {
    const response = await axios.get(
      host + router,
      { params: data }
    );
    return response.data;
  } catch (error) {
    console.error('Ошибка запроса:', error);
    window.location.href = "/not_found"
    throw error;
  }
}


export default apiRequestGet;