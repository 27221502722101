import "./style.css"
import myImage from "./error404.png";

function NotFound () {
  return(
    <section className="error-404-section section-padding">
        <div className="error">
            <img src={myImage} alt=""/>
        </div>
        <div className="error-message">
            <h3>Ой! Страница не найдена!</h3>
            <p>К сожалению мы не можем найти запрошенную вами страницу. Возможно, вы неправильно ввели адрес.</p>
        </div>
    </section>
  )
}

export default NotFound;