import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, createRoutesFromElements, Route} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Profile from "./Pages/profile";
import NotFound from "./Pages/NotFound/404";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/:contactId" element={<Profile />}/>
      <Route path="/not_found" element={<NotFound />}/>
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
reportWebVitals();
